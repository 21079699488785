import './App.css';
import FormInput from './form';

function App() { 
  return (
    <>
      <FormInput/>      
    </>
  );
}

export default App;
