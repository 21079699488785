import './form.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
const MAX_LIMIT = 100;

function FormInput() {
  const [details, setDetails] = useState({
    acctSize: '',
    desrdPosSz: '',
    percentPosSz: '',
    desrdPFRisk: '',
    percentPFRisk: '',
    getQuote: '',
    symbol: '',
    entryPrice: '',
    exitPrice: '',
    potentialPFTakingPc: '',
  });
  const [recshares, setRecshares] = useState(null);
  const [posvalue, setPosvalue] = useState(null);
  const [amountatrisk, setAmountatrisk] = useState(null);
  const [rmultiple, setRmultiple] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [inputField1, setInputField1] = useState(true);
  const [inputField2, setInputField2] = useState(true);
  const [inputField3, setInputField3] = useState(true);
  const [inputField4, setInputField4] = useState(true);
  const [inputField5, setInputField5] = useState(true);
  const [inputField6, setInputField6] = useState(true);
  //for quote button
  const [active, setActive] = useState("get-quote-disabled btn btn-outline-primary");
  const [errorMessage, setErrorMessage] = useState("");

  const [equityOpacity, setEquityOpacity] = useState(false);
  const [posSizeOpacity, setPosSizeOpacity] = useState(false);
  // eslint-disable-next-line
  const [pressed, setPressed] = useState(false);

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/,/g, '');

  var display = true;
  var recAmountShares = 0;
  var posValue = 0;
  var equityAtRisk = 0;

  function updateFields(name, value) {
    setDetails((prev) => {
      return { ...prev, [name]: value }
    });
  }

  const inputEvent = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    updateFields(name, value);
    if (name === "acctSize") {
      setInputField1(true)
    } else if (name === "desrdPFRisk") {
      setInputField2(true)
    } else if (name === "desrdPosSz") {
      setInputField3(true)
    } else if (name === "entryPrice") {
      setInputField4(true)
      updateFields("symbol", "");
    } else if (name === "potentialPFTakingPc") {
      setInputField5(true)
    } else if (name === "exitPrice") {
      setInputField6(true)
    }
  }

  const inputEventQuote = (e) => {
    const value = e.target.value.replace(/[^a-z]/gi, '').toUpperCase();
    const name = e.target.name;
    updateFields(name, value);
    setActive("get-quote btn btn-outline-primary");
  }

  const quoteClick = async () => {
    console.clear()
    setIsValid(true);
    display = true;
    setPressed(true);
    setInputField4(true);
    setActive("get-quote btn btn-outline-primary quotepPressed");

    fetch(`https://traderlion.app/api/calculator/quote`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ symbol: details.symbol }),
    }).then((response) => response.json())
      .then((res) => {
        if (!res.error) {
          setDetails((prev) => {
            return { ...prev, entryPrice: res.result }
          });
        } else {
          setIsValid(false);
          display = false;
          setErrorMessage(res.result);
          setDetails((prev) => {
            return { ...prev, entryPrice: "" }
          });
        }
      }).catch((err) => {
        setIsValid(false);
        display = false;
        setErrorMessage("Server responds with error!");
        console.error(err)
      })
  };

  const inputEventQuityAtRiskDollars = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''));
    var res = parseFloat(value / parseFloat(details.acctSize.replace(/,/g, ''))) * 100;
    const namefield = "percentPFRisk";
    if (value !== "") {
      updateFields(namefield, res);
      setInputField2(true);
      setEquityOpacity(true);
    } else {
      setEquityOpacity(false);
    }
  }

  const inputEventQuityAtRisk = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''));
    var res = ((parseFloat(details.acctSize.replace(/,/g, '')) / 100) * value).toFixed(2);
    const namefield = "desrdPFRisk";
    if (value !== "") {
      updateFields(namefield, res);
      setInputField2(true);
      setEquityOpacity(true);
    } else {
      setEquityOpacity(false);
    }

  }

  const inputEventMaxPosDollars = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''));
    var res = parseFloat(value / parseFloat(details.acctSize.replace(/,/g, ''))) * 100;
    const namefield = "percentPosSz";
    if (value !== "") {
      updateFields(namefield, res);
      setInputField3(true);
      setPosSizeOpacity(true);
    } else {
      setPosSizeOpacity(false);
    }
  }


  const inputEventMaxPos = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''));
    var res = ((parseFloat(details.acctSize.replace(/,/g, '')) / 100) * value).toFixed(2);
    const namefield = "desrdPosSz";
    if (value !== "") {
      updateFields(namefield, res);
      setInputField3(true);
      setPosSizeOpacity(true);
    } else {
      setPosSizeOpacity(false);
    }
  }

  const submitForm = (e) => {
    setIsValid(true);
    e.preventDefault();
    checkValidate();
    handleCalculations();
    if (display) {
      setIsShown(true);
    } else {
      setErrorMessage("All required fields must be filled");
    }
  }

  function checkValidate() {
    if ((details.acctSize) === "") {
      setIsValid(false); setInputField1(false); display = false;
    }
    if ((details.desrdPFRisk) === "") {
      setIsValid(false); setInputField2(false); display = false;
    }
    if ((details.desrdPosSz) === "") {
      setIsValid(false); setInputField3(false); display = false;
    }
    if ((details.entryPrice) === "") {
      setIsValid(false); setInputField4(false); display = false;
    }
    if ((details.exitPrice) === "") {
      setIsValid(false); setInputField6(false); display = false;
    }
  }

  function handleCalculations() {
    //calculation 
    var equity = parseFloat(removeNonNumeric(details.desrdPFRisk));
    equity = equity / 100;
    var posSZ = parseFloat(removeNonNumeric(details.desrdPosSz));
    posSZ = posSZ / 100;
    var diff = parseFloat(removeNonNumeric(details.entryPrice) - removeNonNumeric(details.exitPrice));
    var totalPrice = (equity / diff) * parseFloat(removeNonNumeric(details.entryPrice));
    if (totalPrice >= posSZ) {
      recAmountShares = parseFloat(posSZ / parseFloat((details.entryPrice))).toFixed(0) * 100
    } else {
      recAmountShares = parseFloat(totalPrice / parseFloat((details.entryPrice))).toFixed(0) * 100;
    }
    //calculation 2
    posValue = parseFloat(recAmountShares * parseFloat((details.entryPrice))).toFixed(0);
    //calculation 3
    equityAtRisk = parseFloat(diff * recAmountShares).toFixed(0);
    // R multiple ratio to be calculated    
    var rmult = parseFloat((totalPrice / (parseFloat(removeNonNumeric(details.acctSize)) / 10)) * 100).toFixed(2);

    setRecshares(recAmountShares);
    setPosvalue(addCommas(posValue));
    setAmountatrisk(addCommas(equityAtRisk));
    setRmultiple(rmult);
  }

  return (
    <>
      <div className="container">
        <div className="row header">
          <div className="col-xs-12">
            <div className="header-content">
              <h1 className="header-font-style">Position Size Calculator</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 first-column"><form className="form" onSubmit={submitForm}>
            <div className="form-group input-control">
              <label htmlFor="account_size">Account Size</label>
              <div className={!inputField1 ? 'input-group mb-3 error' : 'input-group mb-3'}>
                <div className="input-group-prepend">
                  <span className="icon-input icon-prefix">$</span>
                </div>
                <NumericFormat
                  className="form-control"
                  id="acctSize"
                  aria-describedby="acctSize"
                  name="acctSize"
                  value={details.acctSize}
                  onChange={inputEvent}
                  decimalScale={2}
                  thousandSeparator=","
                />
              </div>
            </div>
            <div className="form-group input-control">
              <label htmlFor="equity_at_risk">Equity at Risk Per Position</label>
              <div className="row">
                <div className="form-group col-md-6 ps-1 pe-1">
                  <div className={!inputField2 ? 'input-group mb-3 error' : 'input-group mb-3'} >
                    <div className="input-group-prepend"><span className="icon-input icon-prefix">$</span></div>
                    <NumericFormat
                      className="form-control"
                      id="desrdPFRisk"
                      aria-describedby="desrdPFRisk"
                      name="desrdPFRisk"
                      value={details.desrdPFRisk}
                      onKeyUp={inputEventQuityAtRiskDollars}
                      onChange={inputEvent}
                      decimalScale={2}
                      thousandSeparator=","
                    />
                  </div>
                </div>
                <div className="form-group col-md-6 ps-1 pe-1">
                  <div className="input-group mb-3">
                    <NumericFormat
                      className="form-control"
                      id="percentPFRisk"
                      aria-describedby="percentPFRisk"
                      name="percentPFRisk"
                      value={details.percentPFRisk}
                      onKeyUp={inputEventQuityAtRisk}
                      onChange={inputEvent}
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue <= MAX_LIMIT;
                      }}
                      decimalScale={2}
                      thousandSeparator=","
                    />
                    <div className="input-group-prepend"><span className="icon-input icon-postfix">%</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group input-control">
              <label htmlFor="position_size">MAX Position Size Allowed</label>
              <div className="row">
                <div className="form-group col-md-6 ps-1 pe-1">
                  <div className={!inputField3 ? 'input-group mb-3 error' : 'input-group mb-3'}>
                    <div className="input-group-prepend"><span className="icon-input icon-prefix">$</span></div>
                    <NumericFormat
                      className="form-control"
                      id="desrdPosSz"
                      aria-describedby="desrdPosSz"
                      name="desrdPosSz"
                      value={details.desrdPosSz}
                      onKeyUp={inputEventMaxPosDollars}
                      onChange={inputEvent}
                      decimalScale={2}
                      thousandSeparator=","
                    />
                  </div>
                </div>
                <div className="form-group col-md-6 ps-1 pe-1">
                  <div className="input-group mb-3">
                    <NumericFormat
                      className="form-control"
                      id="percentPosSz"
                      aria-describedby="percentPosSz"
                      name="percentPosSz"
                      value={details.percentPosSz}
                      onKeyUp={inputEventMaxPos}
                      onChange={inputEvent}
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === "" || floatValue <= MAX_LIMIT;
                      }}
                      decimalScale={2}
                      thousandSeparator=","
                    />

                    <div className="input-group-prepend"><span className="icon-input icon-postfix">%</span></div>
                  </div>
                </div>
              </div>

            </div>
            <div className="form-group input-control">
              <div className="row">
                <div className="form-group col-md-6 pe-1 ps-1">
                  <label htmlFor="entry_point">Entry Point</label>
                  <div className={!inputField4 ? 'input-group mb-3 error' : 'input-group mb-3'} >
                    <div className="input-group-prepend"><span className="icon-input icon-prefix">$</span></div>
                    <input type="text" className="form-control" id="entryPrice" aria-describedby="entryPrice" name="entryPrice"
                      value={details.entryPrice}
                      onChange={inputEvent}
                    />
                  </div>
                </div>
                <div className="form-group col-md-3 ps-1 pe-1 ">
                  <label htmlFor="symbol">Symbol</label>
                  <input type="text" className="form-control" id="symbol" aria-describedby="symbol" name="symbol"
                    onChange={inputEventQuote}
                    value={details.symbol}
                  />
                </div>
                <div className="form-group col-md-3 ps-2 pe-2">
                  <label></label>
                  <button type="button" className={active}
                    onClick={quoteClick}
                    disabled={!details.symbol}
                  >Get Quote</button>
                </div>
              </div>

            </div>
            <div className="form-group input-control">
              <label htmlFor="potential_profit">Potential Profit Taking Price</label>
              <div className={!inputField5 ? 'input-group mb-3 error' : 'input-group mb-3'}>
                <div className="input-group-prepend"><span className="icon-input icon-prefix">$</span></div>
                <NumericFormat
                  className="form-control"
                  id="potentialPFTakingPc"
                  aria-describedby="potentialPFTakingPc"
                  name="potentialPFTakingPc"
                  value={details.potentialPFTakingPc}
                  onChange={inputEvent}
                  decimalScale={2}
                  thousandSeparator=","
                />
              </div>
            </div>
            <div className="form-group input-control">
              <label htmlFor="exit_price">Potential Stop Loss Exit Price</label>
              <div className={!inputField6 ? 'input-group mb-3 error' : 'input-group mb-3'}>
                <div className="input-group-prepend"><span className="icon-input icon-prefix">$</span></div>
                <NumericFormat
                  className="form-control"
                  id="exitPrice"
                  aria-describedby="exitPrice"
                  name="exitPrice"
                  value={details.exitPrice}
                  onChange={inputEvent}
                  decimalScale={2}
                  thousandSeparator=","
                />
              </div>
            </div>
            {!isValid && (<div className="form-group input-control">
              <span className="error-message">{errorMessage}</span>
            </div>)}
            <div className="form-group input-control">
              <button className="btn btn-primary button-65">Calculate</button>
            </div></form>
          </div>
          <div className="col-sm-6 second-column">
            <div className="form-group output-header">
              <h2>Results</h2>
              {!isShown && (<span className='before-calculation'>
                Please, enter the values in the field to the left to calculate.
              </span>)}
            </div>
            {isShown && (<div className="form-group after-calculation">
              <div className="output-group result-header">
                <h1>{recshares}</h1>
              </div>
              <div className="row"><span className="result-descr">Position Size (Shares)</span></div>
              <div className="output-group vert-row">
                <div className="form-group col-md-6">
                  <label className="result-label">Position Value</label>
                </div>
                <div className="form-group col-md-6 ">
                  <div className="input-group mb-3 result-labe">
                    <label className='col-sm-6'>
                      ${posvalue}
                    </label>
                  </div>
                </div>
              </div>
              <div className="output-group vert-row">
                <div className="form-group col-md-6 ">
                  <label className="result-label">$ at Risk</label>
                </div>
                <div className="form-group col-md-6">
                  <div className="input-group mb-3 result-labe">
                    <label className='col-sm-6'>${amountatrisk}</label>
                  </div>
                </div>
              </div>
              <div className="output-group vert-row">
                <div className="form-group col-md-6 ">
                  <label className="result-label">R Multiple</label>
                </div>
                <div className="form-group col-md-6">
                  <div className="input-group mb-3 result-labe">
                    <label className='col-sm-6'>{rmultiple}</label>
                  </div>
                </div>
              </div>
            </div>)}

          </div>
          <div className='form-group'>
            <div className='col-md-12 sponsor_link'>
              <a href='https://deepvue.com'>Powered by Deepvue</a>
            </div>
          </div>
        </div>
      </div >
      <div></div>

    </>
  );
}

export default FormInput;